import React from 'react';
import Layout from '../components/Layout';
import Heading from '../components/Heading';
import Maps from '../components/Maps';


export default function Location() {
  return (
    <Layout>
        <Heading>
          Location
        </Heading>
        <Maps/>
    </Layout>
  );
}