import React, {useRef, useEffect} from "react";

const Maps = () => {
        const gmap = useRef(null);
        useEffect(() => {
            const styles = [
                {
                  "elementType": "labels",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                },
                {
                  "featureType": "road",
                  "stylers": [
                    {
                      "visibility": "on"
                    }
                  ]
                },
                {
                  "featureType": "road",
                  "elementType": "labels",
                  "stylers": [
                    {
                      "visibility": "on"
                    }
                  ]
                }
              ]
          const script = document.createElement('script');
      
          script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.API_KEY}&libraries=places`;
          script.async = true;
          script.defer = true;
          script.onload = function() {
            let map = new window.google.maps.Map(gmap.current, {
                center: {lat: 35.63665659999999, lng: -82.57613239999999},
                zoom: 14,
                disableDefaultUI: true
              });

            map.setOptions({styles});
            const request = {
                location: map.getCenter(),
                radius: '2',
                query: 'Dermatology Consultants of Asheville'
              };

            let service = new window.google.maps.places.PlacesService(map);

            function callback(results, status) {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    console.log(results)
                    let marker = new window.google.maps.Marker({
                        map: map,
                        place: {
                          placeId: results[0].place_id,
                          location: results[0].geometry.location
                        },
                        title: 'Click for directions'
                      })
                    
                    marker.addListener('click', function() {
                        const url = 'https://www.google.com/maps/dir/?api=1&destination=Dermatology+Consultants+of+Asheville,+PLLC&destination_place_id=ChIJ1TgC-POLWYgRv5xn3DGvas0';
                        window.open(url);
                    });
                }
            }


              service.textSearch(request, callback);
          }
      
          document.body.appendChild(script);
      
          return () => {
            document.body.removeChild(script);
          }
        }, [])

        return (
            <div ref={gmap} id="map" style={{width:'100%',height:'300px'}}></div>
        )

}

export default Maps;